import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { graphql } from 'gatsby';
import { FaEnvelope, FaFacebookF, FaLinkedinIn, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import Layout from '../layouts';
import SingleArticle from '../components/Banners/SingleArticle';
import SEO from '../components/SearchEngine';
import * as styles from './singlearticleprod.module.scss';

const SingleArticleProd = ({ location, data }) => {
  const { siteUrl } = data.site.siteMetadata;
  const canonicalUrl = siteUrl + location.pathname;

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  const hashtags = [frontmatter.category === 'Educate' ? 'TAIKeducates' : 'TAIKinforms'];

  const hashtag = frontmatter.category === 'Educate' ? 'TAIKeducates' : 'TAIKinforms';

  const customTwitterTitle = `${frontmatter.title} #${hashtag} via @theafricaiknow_`;

  const bannerData = {
    title: frontmatter.title,
    author: frontmatter.author,
    date: frontmatter.date,
    category: frontmatter.category,
    photo_credit: frontmatter.photo_credit,
  };

  return (
    <Layout
      bannerHeight='700px'
      bannerStyle={{ backgroundImage: 'none' }}
      dynamicBanner={frontmatter.image_url.childImageSharp.fluid.src}
      bannerChildren={<SingleArticle articleData={bannerData} />}
    >
      <SEO
        title={frontmatter.title}
        image={`${siteUrl}${frontmatter.image_url.childImageSharp.fluid.src}`}
        description={frontmatter.excerpt}
        type='article'
        url={canonicalUrl}
      />

      <section className='sections bg-altsuperlight w-100'>
        <div className={`${styles.article_container}`}>
          <div className={`${styles.share_vertical} d-none d-md-block`}>
            <h2>Share</h2>
            {/* <TwitterShareButton
              className={`me-3 ${styles.social}`}
              title={frontmatter.title}
              hashtags={hashtags}
              url={canonicalUrl}
              via='theafricaiknow_'
            >
              <FaTwitter size={27} />
            </TwitterShareButton> */}
            <TwitterShareButton
              className={`me-3 ${styles.social}`}
              title={customTwitterTitle}
              url={canonicalUrl}
            >
              <FaTwitter size={27} />
            </TwitterShareButton>

            <FacebookShareButton
              className={`${styles.social}`}
              hashtags={hashtags}
              url={canonicalUrl}
              quote={frontmatter.title}
            >
              <FaFacebookF size={27} />
            </FacebookShareButton>

            <LinkedinShareButton
              className={`${styles.social}`}
              title={frontmatter.title}
              url={canonicalUrl}
            >
              <FaLinkedinIn size={27} />
            </LinkedinShareButton>

            <WhatsappShareButton
              className={`${styles.social} ${styles.hideOnBigScreen}`}
              title={frontmatter.title}
              url={canonicalUrl}
            >
              <FaWhatsapp size={27} />
            </WhatsappShareButton>

            <EmailShareButton
              className={styles.social}
              subject={frontmatter.title}
              body={canonicalUrl}
              url={canonicalUrl}
            >
              <FaEnvelope size={27} />
            </EmailShareButton>
          </div>

          <div
            className={`row ${styles.article_text}`}
            dangerouslySetInnerHTML={{ __html: html }}
          />

          {frontmatter.about_author !== '' && (
            <div className={`${styles.about_author}`}>
              <h2>About the author</h2>
              <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
                {frontmatter.about_author}
              </ReactMarkdown>
            </div>
          )}

          <p className={`${styles.contact_us}`}>
            Would you like to submit an article to us? Contact us at{' '}
            <a href='mailto:editors@theafricaiknow.org'>editors@theafricaiknow.org</a>
          </p>

          <div className={`${styles.share}`}>
            <h2>Share this article</h2>
            <div className='d-flex  flex-md-nowrap flex-wrap'>
              <TwitterShareButton
                className={`mr-3 ${styles.social}`}
                title={frontmatter.title}
                hashtags={hashtags}
                url={canonicalUrl}
                via='theafricaiknow_'
              >
                <FaTwitter size={27} />
              </TwitterShareButton>

              <FacebookShareButton
                className={`me-3 ${styles.social}`}
                hashtags={hashtags}
                url={canonicalUrl}
                quote={frontmatter.title}
              >
                <FaFacebookF size={27} />
              </FacebookShareButton>

              <LinkedinShareButton
                className={`me-3 ${styles.social}`}
                title={frontmatter.title}
                url={canonicalUrl}
              >
                <FaLinkedinIn size={27} />
              </LinkedinShareButton>

              <WhatsappShareButton
                className={`me-3 ${styles.social} ${styles.hideOnBigScreen}`}
                title={frontmatter.title}
                url={canonicalUrl}
              >
                <FaWhatsapp size={27} />
              </WhatsappShareButton>

              <EmailShareButton
                className={`me-3 ${styles.social}`}
                subject={frontmatter.title}
                body={canonicalUrl}
                url={canonicalUrl}
              >
                <FaEnvelope size={27} />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        id
        slug
        title
        date
        excerpt
        author
        about_author
        photo_credit
        category
        image_url {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default SingleArticleProd;
