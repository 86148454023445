import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import moment from 'moment';

import * as styles from './SingleArticle.module.scss';

const SingleArticle = ({ articleData }) => {
  const { title, author, category, photo_credit, date } = articleData;

  return (
    <>
      <div className='container'>
        <div className='column'>
          <h1 className={`${styles.title}`}>{title}</h1>

          <div className={`bg-white rounded-pill ${styles.banner_divider}`} />

          <p className={`${styles.author}`}>
            By {author} &bull; {moment(date).format('MMMM DD, YYYY')}
          </p>

          <span className={`rounded-pill ${styles.category}`}>#{category}</span>
        </div>
      </div>
      {photo_credit !== '' && (
        <span className={`${styles.cover_credits}`}>
          {/* Photo credit: <div dangerouslySetInnerHTML={{ __html: photo_credit }} /> */}
          Photo credit:{' '}
          <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
            {photo_credit}
          </ReactMarkdown>
        </span>
      )}
    </>
  );
};

export default SingleArticle;
